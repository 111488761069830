@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "pretendard";
    src: url(./fonts/PretendardVariable.ttf) format("truetype");
  }
  html {
    font-family: "pretendard";
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

 .CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
  width: 30px;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

/* .date-input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
} */

.custom-checkbox1 {
  display: none;
}
.custom-checkbox1-disabled {
  display: none;
}

.custom-checkbox1 + label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.custom-checkbox1-disabled + label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.custom-checkbox1 + label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #caded2; /* Gray-600 */
  border-radius: 4px;
  background-color: #ffffff;
  margin-right: 8px;
}
.custom-checkbox1-disabled + label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #e0e0e0; /* Gray-600 */
  border-radius: 4px;
  background-color: white;
  margin-right: 8px;
}

.custom-checkbox1:checked + label::before {
  background-color: #1c8de3; /* Green-500 */
  border-color: #1c8de3; /* Green-500 */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  content: url("./assets/checked.svg");
  font-size: 16px;
}

.custom-checkbox1-disabled:checked + label::before {
  background-color: #e0e0e0; /* Green-500 */
  border-color: #e0e0e0; /* Green-500 */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  content: url("./assets/checked.svg");
  font-size: 16px;
}

.custom-checkbox {
  display: none;
}
.custom-checkbox-disabled {
  display: none;
}

.ck-editor__editable {
  min-height: 500px;
  border : none;
  transition: 0.5s ease;
}
.ck-editor__editable :focus{
  outline: none;
  border: none;
}
.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
  border : none !important;
  background-color: #FAFBFD;
}
.ck.ck-editor__editable_inline {
  border : none !important
}